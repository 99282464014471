import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Parse from 'parse'

export interface Login {
    isLoggedIn: boolean,
    isLoading: boolean,
    isLoginError: boolean,
    loginError: string
}



const restoreLogin = createAsyncThunk(
    'restoreLogin',
    async () => {
        const u = await Parse.User.current()
        if (u) {
            const login: Login = {

                isLoggedIn: true,

                isLoading: false,
                loginError: "",
                isLoginError: false


            }
            return login

        }

    }
)

const login = createAsyncThunk(
    'login',
    async ({ email, password }: { email: string, password: string }) => {


        const user = await Parse.User.logIn(email, password)

        if (user) {
            const login: Login = {

                isLoggedIn: true,

                isLoading: false,

                isLoginError: false,

                loginError: ""




            }
            return login

        }
        else {
            const login: Login = {

                isLoggedIn: false,

                isLoading: false,

                isLoginError: false,

                loginError: ""




            }
            return login

        }








    }
)






const initialState: Login = {
    isLoggedIn: false,
    isLoading: false,
    isLoginError: false,
    loginError: ""



}


export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {

        logout: (state) => {
            state.isLoading = false
            state.isLoggedIn = false
            Parse.User.logOut().then(() => {
                return state

            });



        },


        resetLoginErrors: (state, _) => {
            state.isLoginError = false
            state.loginError = ""
            return state
        },



    },
    extraReducers: (builder) => {
        builder.addCase(restoreLogin.pending, (state, action) => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `pending` action creator
            state.isLoading = true
            return state


        }).addCase(restoreLogin.fulfilled, (state, action) => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `fulfilled` action creator


            // let newState = action.payload
            // newState['landing_id'] = action.meta.arg


            // state = newState
            if (action.payload) {
                state = { ...state, ...action.payload }

            }

            state.isLoading = false

            return state
        }).addCase(restoreLogin.rejected, (state, action) => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `rejected` action creator
            console.log(action);
            localStorage.removeItem('token')

            state.isLoading = false
            return state
        }).addCase(login.pending, (state, action) => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `pending` action creator
            state.isLoading = true
            return state


        }).addCase(login.fulfilled, (state, action) => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `fulfilled` action creator


            state = { ...state, ...action.payload }

            return state
        }).addCase(login.rejected, (state, action) => {
            // both `state` and `action` are now correctly typed
            // based on the slice state and the `rejected` action creator
            console.log(action);
            return state
        })
    }
})


// Action creators are generated for each case reducer function
export const loginActions = { ...loginSlice.actions, restoreLogin, login }

export default loginSlice.reducer