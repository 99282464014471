import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAppSelector, useAppDispatch, AppState } from "./store";

import Parse from "parse";
// import { initializeParse } from "@parse/react";
import { loginActions } from "./slices/login/loginSlice";
import * as Env from "./environments";
import "./css/main.css";
import "./css/layouts/layout-1.css";
import "./css/layouts/e-commerce.css";
import "./css/animate.css";
import "./css/components/left-sidebar-1/styles-lg.css";
import "./css/components/left-sidebar-1/styles-sm.css";
import "./css/components/nprogress.css";
import "./css/components/recharts.css";
import "./css/components/steps.css";
import "./css/components/left-sidebar-3.css";
// import "./index.css";

// import type {AppProps} from "next/app";
// import Head from "next/head";
// import { Provider } from "react-redux";
// import Layout from "./layouts";
// import store from "./store";
// import Router from "next/router";
// import NProgress from "nprogress";

const LoginView = lazy(() => import("./views/login"));
const MainView = lazy(() => import("./views/main"));
// Router.events.on("routeChangeStart", () => NProgress.start());
// Router.events.on("routeChangeComplete", () => NProgress.done());
// Router.events.on("routeChangeError", () => NProgress.done());

Parse.initialize(Env.APPLICATION_ID, Env.JAVASCRIPT_KEY);
Parse.serverURL = Env.SERVER_URL;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((s: AppState) => s.login.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(loginActions.restoreLogin());
    }
  }, [dispatch, isLoggedIn]);

  return (
    <>
      {/* <Provider store={store}> */}
      <BrowserRouter>
        {!isLoggedIn ? (
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={null}>
                  <LoginView />
                </Suspense>
              }
            ></Route>
            <Route path="*" element={<Navigate to="/"></Navigate>} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="*"
              element={
                <Suspense fallback={null}>
                  <MainView />
                </Suspense>
              }
            />
          </Routes>
        )}
      </BrowserRouter>
      {/* </Provider> */}
    </>
  );
};
export default App;
