import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import Parse from 'parse';
import { Poi } from '../models/models';

export interface ResponseObject {
    results: Poi[];
    count: number;
}

export interface ParseResponseObject {
    results: Parse.Object[];
    count: number;
}


type userType = 'parco' | 'turista' | 'residente' | 'abituale'

export interface PoiState {
    isLoading: boolean;
    pois?: ResponseObject
    poi?: Poi
    isSaving?: boolean,
    pagination: {
        size: number,
        page: number,
        searchText: string,
        status?: string[],
        userTypes?: userType[],
        ascending: boolean,
        sortColumn: string
    }
}

const initialState: PoiState = {
    isLoading: false,
    isSaving: false,
    pois: {
        count: 0,
        results: []
    },
    pagination: {
        size: 10,
        page: 0,
        searchText: "",
        status: ["published", "draft"],
        ascending: false,
        sortColumn: 'updatedAt',
        userTypes: ['parco', 'residente', 'turista', 'abituale']
    }
};

const PoiClass = Parse.Object.extend('Poi')

const getPois = createAsyncThunk(
    'getPois',
    async () => {

        const query = new Parse.Query('Poi');
        query.withCount();
        query.notEqualTo('status', 'deleted')
        query.descending('updatedAt');
        query.include('owner_id')
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());
        return { count: count, results: results } as unknown as ResponseObject;
    }
)


const getPaginatedPois = createAsyncThunk(
    'getPaginatedPois',
    async (_, { getState, dispatch }) => {
        const { size, page, searchText, status, userTypes, ascending, sortColumn } = (getState() as AppState).poi.pagination;
        const query = new Parse.Query('Poi');
        query.withCount();
        query.include('owner_id')

        if (searchText) {
            query.fullText('title', searchText);
        }

        if (status) {
            query.containedIn('status', status);
        }

        if (userTypes) {
            query.containedIn('user_type', userTypes)
        }

        if (ascending) {
            query.ascending(sortColumn)
        }
        else {
            query.descending(sortColumn)
        }

        query.skip(page * size);
        query.limit(size)
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());

        if (size * page > count) {
            const page = Math.ceil(count / size) - 1
            dispatch(poiActions.setPaginationParams({ page }))
        }
        return { count: count, results: results } as unknown as ResponseObject
    }
)



const getPoi = createAsyncThunk(
    'getPoi',
    async (id: string) => {
        const query = new Parse.Query('Poi');
        query.include('owner_id')
        const response = (await query.get(id)) as Parse.Object;
        return response.toJSON() as unknown as Poi
    }
)

const createPoi = createAsyncThunk(
    'createPoi',
    async ({ title, description, geom, qr_id, status }: { title: string, description?: string, geom?: number[], qr_id?: string, status?: string }, { getState, dispatch }) => {
        const u = Parse.User.current()

        if (u) {
            const poi: Parse.Object = new PoiClass()
            const user = Parse.User.current()
            poi.set('title', title)
            if (description) poi.set('description', description)
            if (geom) {
                const g: Parse.GeoPoint = new Parse.GeoPoint(geom[0], geom[1])
                poi.set('geom', g)
            }
            if (qr_id) {
                poi.set('qr_id', qr_id)
            }
            if (status) poi.set('status', status)

            poi.set('owner_id', user)
            poi.set('user_type', 'parco')

            await poi.save()
            const { pagination, pois } = (getState() as AppState).poi;
            if (pois && pagination) dispatch(getPaginatedPois())
            return poi.toJSON() as unknown as Poi
        }
        else {
            // console.log('error', 'user')
        }
    }
)


const updatePoi = createAsyncThunk(
    'updatePoi',
    async ({ title, description, qr_id, geom, id, status }: { title?: string, description?: string, qr_id?: string, geom?: number[], id: string, status?: string }, { dispatch, getState }) => {
        const query = new Parse.Query('Poi')

        const poi = await query.get(id)
        if (title) poi.set('title', title)
        if (description) poi.set('description', description)
        if (qr_id) poi.set('qr_id', qr_id)
        if (geom) poi.set('geom', new Parse.GeoPoint(geom[0], geom[1]))
        if (status) poi.set('status', status)
        await poi.save()

        const { pagination, pois } = (getState() as AppState).poi;
        if (pois && pagination) dispatch(getPaginatedPois())
        return poi.toJSON() as unknown as Poi
    }
)

const deletePoi = createAsyncThunk(
    'deletePoi',
    async ({ id }: { id: string, page?: number, size?: number, text?: string }, { dispatch, getState }) => {
        const query = new Parse.Query('Poi')
        const poi = await query.get(id)
        poi.set('status', 'deleted')
        await poi.save()
        const { pagination, pois } = (getState() as AppState).poi;
        if (pois) if (pagination) dispatch(getPaginatedPois())
    }
)


const definitelyDeletePoi = createAsyncThunk(
    'definitelyDeletePoi',
    async ({ id }: { id: string, page?: number, size?: number, text?: string }, { dispatch, getState }) => {
        const query = new Parse.Query('Poi')
        const poi = await query.get(id)
        poi.set('status', 'defdeleted')
        await poi.save()
        const { pagination, pois } = (getState() as AppState).poi;
        if (pois) if (pagination) dispatch(getPaginatedPois())
    }
)


interface PaginationParams {
    page?: number,
    size?: number,
    searchText?: string,
    status?: string[],
    userTypes?: userType[],
    ascending?: boolean,
    sortColumn?: string,
}

export const poisSlice = createSlice({
    name: 'poi',
    initialState,
    reducers: {
        setPaginationParams: (state: PoiState, action: PayloadAction<PaginationParams>) => {
            const { page, size, searchText, sortColumn, ascending, userTypes, status } = action.payload
            if (page !== undefined) state.pagination.page = page
            if (size) state.pagination.size = size
            if (searchText) state.pagination.searchText = searchText
            else state.pagination.searchText = "";
            if (sortColumn) state.pagination.sortColumn = sortColumn;
            if (ascending !== undefined) state.pagination.ascending = ascending
            if (userTypes) state.pagination.userTypes = userTypes
            if (status) state.pagination.status = status
            return state
        },
        resetPoi: (state: PoiState) => {
            state.poi = undefined
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPois.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPois.fulfilled, (state, action) => {
                state.isLoading = false;
                state.pois = action.payload;
            })
            .addCase(getPois.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getPoi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPoi.fulfilled, (state, action) => {
                state.isLoading = false;
                state.poi = action.payload;
            })
            .addCase(getPoi.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getPaginatedPois.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPaginatedPois.fulfilled, (state, action) => {
                state.isLoading = false;
                state.pois = action.payload
            })
            .addCase(getPaginatedPois.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createPoi.pending, (state) => {
                state.isLoading = true;
                state.isSaving = true
            })
            .addCase(createPoi.rejected, (state) => {
                state.isLoading = false;
                state.isSaving = false
            })
            .addCase(createPoi.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSaving = false
                state.poi = action.payload;
            })
            .addCase(updatePoi.pending, (state) => {
                state.isLoading = true;
                state.isSaving = true
            })
            .addCase(updatePoi.rejected, (state) => {
                state.isLoading = false;
                state.isSaving = false
            })
            .addCase(updatePoi.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSaving = false
                state.poi = action.payload;
            })
            .addCase(deletePoi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deletePoi.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deletePoi.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(definitelyDeletePoi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyDeletePoi.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(definitelyDeletePoi.fulfilled, (state) => {
                state.isLoading = false;
            });
    },
});
export const selectPois = (state: AppState) => state.poi;
export const selectPoi = (state: AppState) => state.poi.poi;
export const selectIsLoading = (state: AppState) => state.poi.isLoading;
export const selectIsSaving = (state: AppState) => state.poi.isSaving;
export const selectPagination = (state: AppState) => state.poi.pagination;

export const poiActions = { ...poisSlice.actions, getPois, getPoi, createPoi, deletePoi, definitelyDeletePoi, updatePoi, getPaginatedPois }

export default poisSlice.reducer;
