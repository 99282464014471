import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import Parse from 'parse';
import * as Env from "../../environments";

// import internal from 'stream';
// import { Attributes } from 'react';
import { OwnerId } from '../models/models';
// import { fetchCount } from './counterAPI';

// export interface PoiState {
// import { SignedResponse } from "../models/models";

// }



export interface TrackId {
    title: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface PoiId {
    title: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface UserId {
    username: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface ImageData {
    id: string;
    thumb: string;
    medium: string;
    big: string;
}


export interface Image {


    status: string;
    position: number;
    owner_id: OwnerId;
    user_id: UserId;
    poi_id: PoiId;
    track_id: TrackId;
    title: string;
    description: string;
    data: ImageData;
    owner_type: string;

    createdAt: Date;
    updatedAt: Date;
    objectId: string;

}

export interface ResponseObject {
    results: Image[];
    count: number;
}

export interface ParseResponseObject {
    results: Parse.Object[];
    count: number;
}


export interface ImageState {
    isLoading: boolean;
    uploading: boolean;
    images?: ResponseObject;
    reload: boolean;
    pagination: {
        size: number,
        page: number,
        searchText: string,
        status?: string[],
        userTypes?: string[],
        ascending: boolean,
        sortColumn: string
    }


}


const initialState: ImageState = {
    isLoading: false,
    uploading: false,
    images: {
        count: 0,
        results: []
    },
    reload: false,
    pagination: {
        size: 10,
        page: 0,
        searchText: "",
        status: ["published"],
        ascending: false,
        sortColumn: 'updatedAt',
        userTypes: ['parco', 'abituale', 'residente', 'turista']


    }

};




const getPaginatedImages = createAsyncThunk(
    'getPaginatedImages',
    async (_, { getState }) => {

        const { size, page, status, userTypes, ascending, sortColumn } = (getState() as AppState).image.pagination;

        const query = new Parse.Query('Immagine');
        query.withCount();
        query.include('owner_id');
        query.include('poi_id')
        query.include('track_id')

        // if (searchText) {
        //     query.fullText('title', searchText);
        // }

        if (status) {

            query.containedIn('status', status);
        }

        if (userTypes) {
            query.containedIn('owner_type', userTypes)
        }
        if (sortColumn !== 'poi_id') {
            if (ascending) {
                query.ascending(sortColumn)
            }
            else {
                query.descending(sortColumn)
            }
        }
        query.skip(page * size);
        query.limit(size)
        // query.notEqualTo('status', 'deleted')
        // query.ascending('poi_id');
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());

        let res: ResponseObject = { count: count, results: results } as unknown as ResponseObject;
        if (sortColumn === 'poi_id') {
            res.results.sort((a, b) => {
                const titleA = a.poi_id ? a.poi_id.title : a.track_id.title;
                const titleB = b.poi_id ? b.poi_id.title : b.track_id.title;

                return titleA > titleB ? 1 : (titleA < titleB ? -1 : 0)
            })

            if (ascending) {
                res.results.reverse()
            }

        }



        return res

    }
)


const getImages = createAsyncThunk(
    'getImages',
    async () => {

        const query = new Parse.Query('Immagine');

        query.withCount();
        query.ascending('owner_id,poi_id,track_id')
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
)

const getImagesOfObject = createAsyncThunk(
    'getImagesOfObject',
    async ({ objectId, type }: { objectId: string, type: 'Poi' | 'Track' }) => {



        const query = new Parse.Query('Immagine');
        const Classe = Parse.Object.extend(type);
        const obj = Classe.createWithoutData(objectId);

        query.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
        query.equalTo('status', 'published');
        query.limit(10000)
        query.descending('updatedAt')
        query.include('track_id,poi_id')


        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
);


const deleteImage = createAsyncThunk(
    'deleteImage',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Immagine');
        const v = await query.get(id);
        v.set('status', 'deleted')
        await v.save();

        if (view === 'list') {
            const { pagination, images } = (getState() as AppState).image;
            // dispatch(getImagesOfObject({ objectId, type }))
            if (images && pagination) dispatch(getPaginatedImages())

        }
        else {

            dispatch(getImagesOfObject({ objectId, type }))


        }



    }
)



const definitelyDeleteImage = createAsyncThunk(
    'definitelyDeleteImage',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Immagine');
        const v = await query.get(id);
        v.set('status', 'defdeleted')
        await v.save();

        const { pagination, images } = (getState() as AppState).image;
        // // dispatch(getImagesOfObject({ objectId, type }))
        // if (images && pagination) dispatch(getPaginatedImages())


        if (view === 'list') {
            const { pagination, images } = (getState() as AppState).image;
            // dispatch(getImagesOfObject({ objectId, type }))
            if (images && pagination) dispatch(getPaginatedImages())

        }
        else if (images && pagination) {
            dispatch(getImagesOfObject({ objectId, type }))

        }


    }
)

const removeImage = createAsyncThunk(
    'removeImage',


    async ({ id, objectId, type, view, }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Immagine');
        const v = await query.get(id);
        v.set('status', 'deleted')
        await v.save();

        const { pagination, images } = (getState() as AppState).image;
        if (view === 'list') {
            const { pagination, images } = (getState() as AppState).image;
            // dispatch(getImagesOfObject({ objectId, type }))
            if (images && pagination) dispatch(getPaginatedImages())

        }
        else if (images && pagination) {
            dispatch(getImagesOfObject({ objectId, type }))

        }



    }
)



const definitelyRemoveImage = createAsyncThunk(
    'definitelyRemoveImage',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Immagine');
        const v = await query.get(id);
        v.set('status', 'defdeleted')
        await v.save();

        // const { pagination, images } = (getState() as AppState).image;
        // dispatch(getImagesOfObject({ objectId, type }))


        if (view === 'list') {
            const { pagination, images } = (getState() as AppState).image;
            // dispatch(getImagesOfObject({ objectId, type }))
            if (images && pagination) dispatch(getPaginatedImages())

        }
        else {
            dispatch(getImagesOfObject({ objectId, type }))

        }





    }
)


const publishImage = createAsyncThunk(
    'publishImage',
    async ({ id, objectId, type }: { id: string, objectId: string, type: "Poi" | "Track" }) => {

        const query = new Parse.Query('Immagine');
        const img = await query.get(id);
        img.set('status', 'published')
        await img.save();

        return


    }
)




export const uploadImageFile = createAsyncThunk(
    'uploadImageFile',
    async ({ file, objectId, type }: { file: File, objectId: string, type: "Poi" | "Track" }) => {
        if (file != null) {

            const session = await Parse.Session.current();

            var myHeaders = new Headers();
            // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJleHAiOjE2NjI2OTY2ODV9.6HTB8lttPyCWoETKsARHrAPTwAZg9gRx_tScY_DL13A");
            myHeaders.append('Session', await session.getSessionToken())
            var formdata = new FormData();
            formdata.append("image", file, file.name);
            formdata.append("title", "fff");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
            };

            try {

                await fetch(`${Env.API_URL}/${type.toLowerCase()}s/${objectId}/images`, requestOptions)

                const queryImage = new Parse.Query('Immagine');
                const Classe = Parse.Object.extend(type);
                const obj = Classe.createWithoutData(objectId);

                queryImage.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
                queryImage.equalTo('status', 'published');
                queryImage.limit(10000)
                queryImage.descending('updatedAt')
                const res = await queryImage.find();
                const results = res.map((p) => p.toJSON());

                await new Promise((resolve) => setTimeout(resolve, 4000));

                return { count: res.length, results: results } as unknown as ResponseObject;

            } catch (error) {
                console.log(error)
                const queryImage = new Parse.Query('Immagine');
                const Classe = Parse.Object.extend(type);
                const obj = Classe.createWithoutData(objectId);

                queryImage.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
                queryImage.equalTo('status', 'published');
                queryImage.limit(10000)
                queryImage.descending('updatedAt')
                const res = await queryImage.find();
                const results = res.map((p) => p.toJSON());

                await new Promise((resolve) => setTimeout(resolve, 4000));

                return { count: res.length, results: results } as unknown as ResponseObject;

            }
        }
    }
);

interface PaginationParams {
    page?: number,
    size?: number,
    searchText?: string,
    status?: string[],
    userTypes?: string[],
    ascending?: boolean,
    sortColumn?: string,
}
export const imagesSlice = createSlice({
    name: 'image',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        setPaginationParams: (state: ImageState, action: PayloadAction<PaginationParams>) => {
            const { page, size, sortColumn, ascending, userTypes, status } = action.payload

            if (page !== undefined) state.pagination.page = page
            if (size) state.pagination.size = size
            if (sortColumn) state.pagination.sortColumn = sortColumn
            if (ascending !== undefined) state.pagination.ascending = ascending
            if (userTypes) state.pagination.userTypes = userTypes
            if (status) state.pagination.status = status

            return state
        }


    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getImages.pending, (state) => {
                state.isLoading = true;
                state.reload = false;

            })
            .addCase(getImages.rejected, (state, action) => {
                state.isLoading = false;
                state.reload = false;

            })
            .addCase(getImages.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = false;
                state.images = action.payload;
            })
            .addCase(removeImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeImage.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(removeImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true;

            })
            .addCase(definitelyRemoveImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyRemoveImage.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(definitelyRemoveImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true;

            })
            .addCase(deleteImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteImage.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true;

            })
            .addCase(definitelyDeleteImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyDeleteImage.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(definitelyDeleteImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true;

            })
            .addCase(publishImage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(publishImage.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(publishImage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true;

            })
            .addCase(getImagesOfObject.pending, (state) => {
                state.isLoading = true;
                state.reload = false;
            })
            .addCase(getImagesOfObject.rejected, (state) => {
                state.reload = false;
                state.isLoading = false;

            })
            .addCase(getImagesOfObject.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = false;
                state.images = action.payload;

            })
            .addCase(uploadImageFile.pending, (state) => {
                state.reload = false;
                state.uploading = true;
            })
            .addCase(uploadImageFile.rejected, (state) => {
                state.uploading = false;
                state.reload = false;

            })
            .addCase(uploadImageFile.fulfilled, (state, action) => {
                state.reload = false;
                state.uploading = false;
                state.images = action.payload;

            })
            .addCase(getPaginatedImages.pending, (state) => {
                state.reload = false;
                state.isLoading = true;
            })
            .addCase(getPaginatedImages.rejected, (state, action) => {
                state.reload = false;
                state.isLoading = false;

            })
            .addCase(getPaginatedImages.fulfilled, (state, action) => {
                state.reload = false;
                state.isLoading = false;
                state.images = action.payload;
            });
    },
});




// export const { getPois } = poisSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: AppState) => state.counter.value)`

export const selectImages = (state: AppState) => state.image;
export const selectPagination = (state: AppState) => state.image.pagination;

export const imageActions = { ...imagesSlice.actions, getPaginatedImages, definitelyRemoveImage, deleteImage, definitelyDeleteImage, getImages, publishImage, removeImage, getImagesOfObject, uploadImageFile }

export default imagesSlice.reducer;
