import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import Parse from 'parse';


interface TagToObjParams {
    type: 'Poi' | 'Track';
    objId: string;
    tagId: string;
}

export interface Tag {
    status: string;
    name: string;
    color: string;
    createdAt: Date;
    updatedAt: Date;
    objectId: string;
}

export interface ResponseObject {
    results: Tag[];
    count: number;
}

export interface ParseResponseObject {
    results: Parse.Object[];
    count: number;

}


export interface TagState {
    isLoading: boolean;
    allTags?: Tag[];
    tags?: ResponseObject;
    trackTags?: Tag[];
    poiTags?: Tag[];
    pagination: {
        size: number,
        page: number,
        searchText: string,
        status?: string[],
        userTypes?: string[],
        ascending: boolean,
        sortColumn: string
    }
}

const initialState: TagState = {
    isLoading: false,
    tags: {
        count: 0,
        results: []
    },
    pagination: {
        size: 5,
        page: 0,
        searchText: "",
        status: ["published"],
        ascending: false,
        sortColumn: 'updatedAt',
        userTypes: ['parco']


    }

};

const getAllTags = createAsyncThunk(
    'getAllTags',
    async () => {
        const query = new Parse.Query('Tag');
        const response = (await query.find()) as unknown as Parse.Object[];
        const results = response.map((p) => p.toJSON());
        return results as unknown as Tag[];
    }
)

const getObjectTags = createAsyncThunk(
    'getObjectTags',
    async ({ id, type }: { id: string, type: 'Poi' | 'Track' }) => {
        const query = new Parse.Query(`Tag${type}`);
        const Obj = Parse.Object.extend(type)
        const object_id = Obj.createWithoutData(id)
        query.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', object_id);
        query.limit(1000)
        const response = (await query.find()) as unknown as Parse.Object[];
        const results = response.map((p) => p.toJSON());

        return {
            results: results.map((obj: any) => obj['tag_id']) as unknown as Tag[],
            type: type
        }
    }
)

const getTags = createAsyncThunk(
    'getTags',
    async () => {
        const query = new Parse.Query('Tag');
        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());
        return { count: count, results: results } as unknown as ResponseObject;
    }
)

const addTag = createAsyncThunk(
    'addTag',
    async ({ name, color }: { name: string, color: string }) => {
        const TagClass = Parse.Object.extend('Tag')
        const tag = new TagClass()
        tag.set('color', color)
        tag.set('name', name)
        await tag.save()
        const query = new Parse.Query(TagClass);
        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());
        return { count: count, results: results } as unknown as ResponseObject;
    }
)


const getPaginatedTags = createAsyncThunk(
    'getPaginatedTags',
    async (_, { getState }) => {

        // async ({ size, page, searchText, status, ascending = false, sortColumn = 'updatedAt' }: { size: number, page: number, searchText?: string, status?: string[], ascending?: boolean, sortColumn?: string, }) => {
        const { size, page, searchText, status, ascending, sortColumn } = (getState() as AppState).tag.pagination;

        const query = new Parse.Query('Tag');
        query.withCount();

        if (searchText) {
            query.fullText('name', searchText);
        }

        if (status) {
            query.containedIn('status', status);
        }
        query.skip(page * size);
        query.limit(size)
        if (ascending) {
            query.ascending(sortColumn);
        }
        else {
            query.descending(sortColumn);
        }
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());

        return { count: count, results: results } as unknown as ResponseObject;

    }
)


const removeTag = createAsyncThunk(
    'removeTag',
    async ({ id }: { id: string }, { dispatch, getState }) => {
        const q = new Parse.Query('Tag');
        const tag = await q.get(id)
        await tag.destroy()


        const { pagination, tags } = (getState() as AppState).tag;
        if (tags) if (pagination) dispatch(getPaginatedTags())


    }
)

const updateTag = createAsyncThunk(
    'updateTag',
    async ({ id, name, color }: { id: string, name?: string, color?: string }, { dispatch, getState }) => {
        const q = new Parse.Query('Tag');
        const tag = await q.get(id)
        if (name) {
            tag.set('name', name)
        }
        if (color) {
            tag.set('color', color)
        }
        await tag.save()

        const { pagination, tags } = (getState() as AppState).tag;
        if (tags) if (pagination) dispatch(getPaginatedTags())
    }
)



const addTagToObj = createAsyncThunk(
    'addTagToObj',
    async ({ type, objId, tagId }: TagToObjParams) => {
        const TagClass = Parse.Object.extend('Tag')
        const TagTrackClass = Parse.Object.extend('TagTrack')
        const TagPoiClass = Parse.Object.extend('TagPoi')
        const PoiClass = Parse.Object.extend('Poi')
        const TrackClass = Parse.Object.extend('Track')
        const obj = type === 'Poi' ? PoiClass.createWithoutData(objId) : TrackClass.createWithoutData(objId)
        const tag = TagClass.createWithoutData(tagId)
        const tagObj = type === 'Poi' ? (new TagPoiClass()) : (new TagTrackClass())
        tagObj.set(type === 'Poi' ? 'poi_id' : 'track_id', obj)
        tagObj.set('tag_id', tag)
        await tagObj.save()
        return;
    }
)

const removeTagFromObj = createAsyncThunk(
    'removeTagFromObj',
    async ({ type, objId, tagId }: TagToObjParams) => {
        const TagClass = Parse.Object.extend('Tag')
        // const TagTrackClass = Parse.Object.extend('TagTrack')
        // const TagPoiClass = Parse.Object.extend('TagPoi')
        const PoiClass = Parse.Object.extend('Poi')
        const TrackClass = Parse.Object.extend('Track')
        const obj = type === 'Poi' ? PoiClass.createWithoutData(objId) : TrackClass.createWithoutData(objId)


        const tag = TagClass.createWithoutData(tagId)
        // const tagObj = type === 'Poi' ? (new TagPoiClass()) : (new TagTrackClass())

        const query = new Parse.Query(`Tag${type}`);
        query.equalTo('tag_id', tag);
        query.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
        const tagsObj = await query.find();
        tagsObj.forEach((t) => {
            t.destroy();
        });



        return;
    }
)


interface PaginationParams {
    page?: number,
    size?: number,
    searchText?: string,
    status?: string[],
    userTypes?: string[],
    ascending?: boolean,
    sortColumn?: string,
}

export const tagsSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {

        setPaginationParams: (state: TagState, action: PayloadAction<PaginationParams>) => {
            const { page, size, searchText, sortColumn, ascending, userTypes, status } = action.payload

            if (page !== undefined) state.pagination.page = page
            if (size) state.pagination.size = size
            if (searchText) state.pagination.searchText = searchText
            if (sortColumn) state.pagination.sortColumn = sortColumn
            if (ascending !== undefined) state.pagination.ascending = ascending
            if (userTypes) state.pagination.userTypes = userTypes
            if (status) state.pagination.status = status

            return state
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTags.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllTags.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getAllTags.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allTags = action.payload;
            })
            .addCase(getObjectTags.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getObjectTags.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getObjectTags.fulfilled, (state, action) => {
                state.isLoading = false;

                if (action.payload.type === 'Poi') {
                    state.poiTags = action.payload.results
                }
                else {
                    state.trackTags = action.payload.results
                }
            })

            .addCase(getTags.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTags.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tags = action.payload;
            })
            .addCase(addTag.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addTag.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(addTag.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tags = action.payload;
            })
            .addCase(getPaginatedTags.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPaginatedTags.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getPaginatedTags.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tags = action.payload;
            })
            .addCase(removeTag.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeTag.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(removeTag.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateTag.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateTag.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateTag.fulfilled, (state) => {
                state.isLoading = false;
            })
        // .addCase(addTagToObj.pending, () => {
        // })
        // .addCase(addTagToObj.rejected, () => {
        // })
        // .addCase(addTagToObj.fulfilled, () => {

        // })
        // .addCase(removeTagFromObj.pending, () => {
        // })
        // .addCase(removeTagFromObj.rejected, () => {
        // })
        // .addCase(removeTagFromObj.fulfilled, () => {

        // });
    },
});


export const selectTags = (state: AppState) => state.tag;
export const selectTrackTags = (state: AppState) => state.tag.trackTags;
export const selectPoiTags = (state: AppState) => state.tag.poiTags;
export const selectAllTags = (state: AppState) => state.tag.allTags;
export const selectPagination = (state: AppState) => state.tag.pagination;

export const tagActions = { ...tagsSlice.actions, getAllTags, getTags, getObjectTags, getPaginatedTags, addTag, removeTag, updateTag, addTagToObj, removeTagFromObj }

export default tagsSlice.reducer;
