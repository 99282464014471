import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import Parse from 'parse';
import { Track } from '../models/models';




export interface OwnerId {
    username: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface ResponseObject {
    results: Track[];
    count: number;
}

export interface ParseResponseObject {
    results: Parse.Object[];
    count: number;
}


export interface TrackState {
    isLoading: boolean;
    tracks?: ResponseObject;
    track?: Track;
    trackDetails?: {
        distance: number;
        elevationMax: number;
        geomArray: number[][][];
        geom: number[]
    }
    isSaving?: boolean,

    pagination: {
        size: number,
        page: number,
        searchText: string,
        status?: string[],
        userTypes?: string[],
        ascending: boolean,
        sortColumn: string
    }

}

const initialState: TrackState = {
    isLoading: false,
    tracks: {
        count: 0,
        results: []
    },
    pagination: {
        size: 10,
        page: 0,
        searchText: "",
        status: ["published", "draft"],
        ascending: false,
        sortColumn: 'updatedAt',
        userTypes: ['parco', 'turista', 'abituale', 'residente']


    }


};

const TrackClass = Parse.Object.extend('Track')



const getTracks = createAsyncThunk(
    'getTracks',
    async () => {

        const query = new Parse.Query('Track');
        query.withCount();
        query.descending('updatedAt');
        query.include('owner_id')

        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
)

const getPaginatedTracks = createAsyncThunk(
    'getPaginatedTracks',
    async (_, { getState, dispatch }) => {

        // async ({ size, page, searchText, status, userTypes }: { size: number, page: number, searchText?: string, status?: string[], userTypes?: string[] }) => {
        const { size, page, searchText, status, userTypes, ascending, sortColumn } = (getState() as AppState).track.pagination;

        const query = new Parse.Query('Track');
        query.withCount();
        query.include('owner_id')

        if (searchText) {
            query.fullText('title', searchText);
        }

        if (status) {

            query.containedIn('status', status);
        }

        if (userTypes) {
            query.containedIn('user_type', userTypes)
        }

        if (ascending) {
            query.ascending(sortColumn)
        }
        else {
            query.descending(sortColumn)
        }

        query.skip(page * size);
        query.limit(size)
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());

        if (size * page > count) {
            const page = Math.ceil(count / size) - 1
            dispatch(trackActions.setPaginationParams({ page }))
        }





        return { count: count, results: results } as unknown as ResponseObject;

    }
)

const getTrack = createAsyncThunk(
    'getTrack',
    async (id: string) => {

        const query = new Parse.Query('Track');
        query.include('owner_id')

        const response = (await query.get(id)) as Parse.Object;
        const track: Track = response.toJSON() as unknown as Track



        return {
            track, trackDetails: { distance: track.distance ? track.distance : 0, elevationMax: track.ascent ? track.ascent : 0, geomArray: track.geom_array ? track.geom_array : [], geom: track.geom ? track.geom : [] }
        }

    }
)


const createTrack = createAsyncThunk(
    'createTrack',
    async ({ title, description, geom, geom_array, distance, ascent }: { title: string, description: string, geom: number[], geom_array: number[][][], distance?: number, ascent?: number }, { getState, dispatch }) => {
        const u = Parse.User.current()
        if (u) {
            const track: Parse.Object = new TrackClass()
            const user = Parse.User.current()
            track.set('title', title)
            if (description) track.set('description', description)

            if (geom) {
                const g: Parse.GeoPoint = new Parse.GeoPoint(geom[0], geom[1])
                track.set('geom', g)
            }
            if (geom_array) {
                track.set('geom_array', geom_array);
            }
            if (distance) {
                track.set('distance', distance);

            }
            if (ascent) {
                track.set('ascent', ascent);

            }
            track.set('owner_id', user)
            track.set('user_type', 'parco')
            await track.save()


            const { pagination, tracks } = (getState() as AppState).track;
            if (tracks && pagination) dispatch(getPaginatedTracks())


            return track.toJSON() as unknown as Track
        }
        else {
            console.log('error', 'user')
        }


    }
)


const updateTrack = createAsyncThunk(
    'updateTrack',
    async ({ title, description, duration, difficulty, ascent, distance, types, geom, geom_array, id, category, status }:
        { title?: string, description?: string, duration?: number, difficulty?: string, ascent?: number, distance?: number, types?: string[], geom?: number[], geom_array?: any, id: string, status?: string, category?: string[], }, { dispatch, getState }) => {
        const query = new Parse.Query('Track')

        const track = await query.get(id)
        if (title) track.set('title', title)
        if (description) track.set('description', description)
        if (difficulty) track.set('difficulty', difficulty)
        if (duration) track.set('duration', duration)
        if (ascent) track.set('ascent', ascent)
        if (distance) track.set('distance', distance)
        if (types) track.set('category', types)
        if (geom) track.set('geom', new Parse.GeoPoint(geom[0], geom[1]))
        if (geom_array) track.set('geom_array', geom_array)
        if (status) track.set('status', status)
        if (category) track.set("category", category)
        await track.save()


        const { pagination, tracks } = (getState() as AppState).track;
        if (tracks && pagination) dispatch(getPaginatedTracks())




        return track.toJSON() as unknown as Track

    }
)

const deleteTrack = createAsyncThunk(
    'deleteTrack',
    async ({ id, page, size, text }: { id: string, page?: number, size?: number, text?: string }, { dispatch, getState }) => {
        let query = new Parse.Query('Track')
        const track = await query.get(id)
        track.set('status', 'deleted')
        await track.save()



        const { pagination, tracks } = (getState() as AppState).track;
        if (tracks && pagination) dispatch(getPaginatedTracks())





    }
)

const definitelyDeleteTrack = createAsyncThunk(
    'definitelyDeleteTrack',
    async ({ id, page, size, text }: { id: string, page?: number, size?: number, text?: string }, { dispatch, getState }) => {
        let query = new Parse.Query('Track')
        const track = await query.get(id)
        track.set('status', 'defdeleted')
        await track.save()



        const { pagination, tracks } = (getState() as AppState).track;
        if (tracks && pagination) dispatch(getPaginatedTracks())





    }
)




interface PaginationParams {
    page?: number,
    size?: number,
    searchText?: string,
    status?: string[],
    userTypes?: string[],
    ascending?: boolean,
    sortColumn?: string,
}


export const tracksSlice = createSlice({
    name: 'track',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateTrackDetails: (state: TrackState, action: PayloadAction<{ distance: number, geomArray: number[][][], geom: number[], elevationMax: number }>) => {
            state.trackDetails = {
                ...action.payload
            }
        },
        resetTrack: (state: TrackState,) => {
            state.track = undefined
        },
        setPaginationParams: (state: TrackState, action: PayloadAction<PaginationParams>) => {
            const { page, size, searchText, sortColumn, ascending, userTypes, status } = action.payload

            if (page !== undefined) state.pagination.page = page
            if (size) state.pagination.size = size
            if (searchText) state.pagination.searchText = searchText
            else state.pagination.searchText = "";
            if (sortColumn) state.pagination.sortColumn = sortColumn
            if (ascending !== undefined) state.pagination.ascending = ascending
            if (userTypes) state.pagination.userTypes = userTypes
            if (status) state.pagination.status = status

            return state
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getTracks.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTracks.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tracks = action.payload;
            })
            .addCase(getPaginatedTracks.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPaginatedTracks.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getPaginatedTracks.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tracks = action.payload;
            })
            .addCase(getTrack.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTrack.fulfilled, (state, action) => {
                state.isLoading = false;
                state.track = action.payload.track;
                state.trackDetails = action.payload.trackDetails
            })
            .addCase(getTrack.rejected, (state) => {
                state.isLoading = false;
            })

            .addCase(createTrack.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createTrack.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createTrack.fulfilled, (state, action) => {
                state.isLoading = false;
                state.track = action.payload;
            })
            .addCase(updateTrack.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateTrack.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateTrack.fulfilled, (state, action) => {
                state.isLoading = false;
                state.track = action.payload;
            })
            .addCase(deleteTrack.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteTrack.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteTrack.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(definitelyDeleteTrack.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyDeleteTrack.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(definitelyDeleteTrack.fulfilled, (state, action) => {
                state.isLoading = false;
            });
    },
});

// export const { getTracks } = tracksSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: AppState) => state.counter.value)`

export const selectTracks = (state: AppState) => state.track;
export const selectTrackDetails = (state: AppState) => state.track.trackDetails;
export const selectTrack = (state: AppState) => state.track.track;
export const selectPagination = (state: AppState) => state.track.pagination;

export const trackActions = { ...tracksSlice.actions, getTracks, getPaginatedTracks, definitelyDeleteTrack, getTrack, createTrack, updateTrack, deleteTrack }

export default tracksSlice.reducer;
