import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import Parse from 'parse';
import { OwnerId } from '../models/models';

import * as Env from "../../environments";



export interface TrackId {
    title: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface PoiId {
    title: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface UserId {
    username: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface AudioData {
    id: string;
    mp3: string;
}


export interface Audio {


    status: string;
    owner_id: OwnerId;
    owner_type: string;
    user_id: UserId;
    poi_id: PoiId;
    track_id: TrackId;
    title: string;
    description: string;
    data: AudioData;

    createdAt: Date;
    updatedAt: Date;
    objectId: string;

}

export interface ResponseObject {
    results: Audio[];
    count: number;
}

export interface ParseResponseObject {
    results: Parse.Object[];
    count: number;
}


export interface AudioState {
    isLoading: boolean;
    uploading: boolean;
    audio?: ResponseObject;
    pagination: {
        size: number,
        page: number,
        searchText: string,
        status: string[],
        userTypes: string[],
        ascending: boolean,
        sortColumn: string
    }

}


const initialState: AudioState = {
    isLoading: false,
    uploading: false,
    audio: {
        count: 0,
        results: []
    },
    pagination: {
        size: 10,
        page: 0,
        searchText: "",
        status: ["published"],
        ascending: false,
        sortColumn: 'updatedAt',
        userTypes: ['parco', 'turista', 'abituale', 'residente']


    }


};


const getPaginatedAudio = createAsyncThunk(
    'getPaginatedAudio',
    // async ({ size, page, searchText, status, sortColumn = 'updatedAt', ascending = false }: { size: number, page: number, searchText?: string, status?: string[], sortColumn?: string, ascending?: boolean }) => {
    async (_, { getState }) => {
        const { size, page, searchText, status, userTypes, ascending, sortColumn } = (getState() as AppState).audio.pagination;

        const query = new Parse.Query('Audio');
        query.withCount();
        query.include('owner_id');
        query.include('poi_id')
        query.include('track_id')


        if (searchText) {
            query.fullText('title', searchText);
        }

        if (userTypes) {
            query.containedIn('owner_type', userTypes)
        }

        if (status) {

            query.containedIn('status', status);
        }

        if (sortColumn !== 'poi_id') {
            if (ascending) {
                query.ascending(sortColumn)
            }
            else {
                query.descending(sortColumn)
            }
        }
        query.skip(page * size);
        query.limit(size)
        // query.notEqualTo('status', 'deleted')
        query.ascending('title');
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());


        let res: ResponseObject = { count: count, results: results } as unknown as ResponseObject;

        if (sortColumn === 'poi_id') {
            res.results.sort((a, b) => {
                const titleA = a.poi_id ? a.poi_id.title : a.track_id.title;
                const titleB = b.poi_id ? b.poi_id.title : b.track_id.title;

                return titleA > titleB ? 1 : (titleA < titleB ? -1 : 0)
            })

            if (ascending) {
                res.results.reverse()
            }

        }





        return res;

    }
)


const getAudio = createAsyncThunk(
    'getAudio',
    async () => {

        const query = new Parse.Query('Audio');
        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
)

const getAudioOfObject = createAsyncThunk(
    'getAudioOfObject',
    async ({ objectId, type }: { objectId: string, type: 'Poi' | 'Track' }) => {



        const query = new Parse.Query('Audio');
        const Classe = Parse.Object.extend(type);
        const obj = Classe.createWithoutData(objectId);

        query.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
        query.equalTo('status', 'published');
        query.limit(10000)
        query.descending('updatedAt')


        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
);

const removeAudio = createAsyncThunk(
    'removeAudio',
    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Audio');
        const v = await query.get(id);
        v.set('status', 'deleted')
        await v.save()
        // dispatch(getAudioOfObject({ objectId, type }))

        // const { pagination, audio } = (getState() as AppState).audio;
        // if (audio && pagination) dispatch(getPaginatedAudio())


        if (view === 'list') {
            const { pagination, audio } = (getState() as AppState).audio;
            if (audio && pagination) dispatch(getPaginatedAudio())

        }
        else {

            dispatch(getAudioOfObject({ objectId, type }))


        }




        // const { pagination, audio } = (getState() as AppState).audio;
        // if (audio && pagination) dispatch(getPaginatedAudio())

    }
)

const deleteAudio = createAsyncThunk(
    'deleteAudio',
    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Audio');
        const v = await query.get(id);
        v.set('status', 'deleted')
        await v.save()

        // const { pagination, audio } = (getState() as AppState).audio;
        // if (audio && pagination) dispatch(getPaginatedAudio())


        if (view === 'list') {
            const { pagination, audio } = (getState() as AppState).audio;
            if (audio && pagination) dispatch(getPaginatedAudio())

        }
        else {

            dispatch(getAudioOfObject({ objectId, type }))


        }

    }
)


const definitelyDeleteAudio = createAsyncThunk(
    'definitelyDeleteAudio',
    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Audio');
        const v = await query.get(id);
        v.set('status', 'defdeleted')
        await v.save()



        if (view === 'list') {
            const { pagination, audio } = (getState() as AppState).audio;
            if (audio && pagination) dispatch(getPaginatedAudio())

        }
        else {

            dispatch(getAudioOfObject({ objectId, type }))


        }

    }
)


const definitelyRemoveAudio = createAsyncThunk(
    'definitelyRemoveAudio',
    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Audio');
        const v = await query.get(id);
        v.set('status', 'defdeleted')
        await v.save()


        // dispatch(getAudioOfObject({ objectId, type }))

        if (view === 'list') {
            const { pagination, audio } = (getState() as AppState).audio;
            if (audio && pagination) dispatch(getPaginatedAudio())

        }
        else {

            dispatch(getAudioOfObject({ objectId, type }))


        }


    }
)


export const uploadAudioFile = createAsyncThunk(
    'uploadAudioFile',
    async ({ file, objectId, type }: { file: File, objectId: string, type: "Poi" | "Track" }) => {
        if (file != null) {

            const session = await Parse.Session.current();

            var myHeaders = new Headers();
            // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJleHAiOjE2NjI2OTY2ODV9.6HTB8lttPyCWoETKsARHrAPTwAZg9gRx_tScY_DL13A");
            myHeaders.append('Session', await session.getSessionToken())
            var formdata = new FormData();
            formdata.append("audio", file, file.name);
            formdata.append("title", "fff");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
            };

            try {

                await fetch(`${Env.API_URL}/${type.toLowerCase()}s/${objectId}/audios`, requestOptions)

                const queryImage = new Parse.Query('Audio');
                const Classe = Parse.Object.extend(type);
                const obj = Classe.createWithoutData(objectId);

                queryImage.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
                queryImage.equalTo('status', 'published');
                queryImage.limit(10000)
                queryImage.descending('updatedAt')
                const res = await queryImage.find();
                const results = res.map((p) => p.toJSON());

                await new Promise((resolve) => setTimeout(resolve, 4000));

                return { count: res.length, results: results } as unknown as ResponseObject;

            } catch (error) {
                console.log(error)
                const queryImage = new Parse.Query('Audio');
                const Classe = Parse.Object.extend(type);
                const obj = Classe.createWithoutData(objectId);

                queryImage.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
                queryImage.equalTo('status', 'published');
                queryImage.limit(10000)
                queryImage.descending('updatedAt')
                const res = await queryImage.find();
                const results = res.map((p) => p.toJSON());

                await new Promise((resolve) => setTimeout(resolve, 4000));

                return { count: res.length, results: results } as unknown as ResponseObject;

            }
        }
    }
);

interface PaginationParams {
    page?: number,
    size?: number,
    searchText?: string,
    status?: string[],
    userTypes?: string[],
    ascending?: boolean,
    sortColumn?: string,
}

export const audioSlice = createSlice({
    name: 'audio',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPaginationParams: (state: AudioState, action: PayloadAction<PaginationParams>) => {
            const { page, size, searchText, sortColumn, ascending, userTypes, status } = action.payload

            if (page !== undefined) state.pagination.page = page
            if (size) state.pagination.size = size
            if (searchText) state.pagination.searchText = searchText
            if (sortColumn) state.pagination.sortColumn = sortColumn
            if (ascending !== undefined) state.pagination.ascending = ascending
            if (userTypes) state.pagination.userTypes = userTypes
            if (status) state.pagination.status = status
            return state
        }

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getPaginatedAudio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPaginatedAudio.rejected, (state, action) => {
                state.isLoading = false;

            })
            .addCase(getPaginatedAudio.fulfilled, (state, action) => {
                state.isLoading = false;
                state.audio = action.payload;
            })
            .addCase(getAudio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAudio.rejected, (state, action) => {
                state.isLoading = false;

            })
            .addCase(getAudio.fulfilled, (state, action) => {
                state.isLoading = false;
                state.audio = action.payload;
            })
            .addCase(definitelyRemoveAudio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyRemoveAudio.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(definitelyRemoveAudio.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.audio = action.payload;

            })
            .addCase(removeAudio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeAudio.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(removeAudio.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.audio = action.payload;

            })
            .addCase(definitelyDeleteAudio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyDeleteAudio.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(definitelyDeleteAudio.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.audio = action.payload;

            })
            .addCase(deleteAudio.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteAudio.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(deleteAudio.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.audio = action.payload;

            })
            .addCase(getAudioOfObject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAudioOfObject.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(getAudioOfObject.fulfilled, (state, action) => {
                state.isLoading = false;
                state.audio = action.payload;

            })
            .addCase(uploadAudioFile.pending, (state) => {
                state.uploading = true;
            })
            .addCase(uploadAudioFile.rejected, (state) => {
                state.uploading = false;

            })
            .addCase(uploadAudioFile.fulfilled, (state, action) => {
                state.uploading = false;
                state.audio = action.payload;

            });
    },
});




// export const { getPois } = poisSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: AppState) => state.counter.value)`

export const selectAudio = (state: AppState) => state.audio;
export const selectPagination = (state: AppState) => state.audio.pagination;

export const audioActions = { ...audioSlice.actions, getPaginatedAudio, definitelyDeleteAudio, deleteAudio, getAudio, definitelyRemoveAudio, removeAudio, getAudioOfObject, uploadAudioFile }

export default audioSlice.reducer;
