import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import colorsReducer from "./slices/colors";
import configReducer from "./slices/config";
import leftSidebarReducer from "./slices/left-sidebar";
import navigationReducer from "./slices/navigation";
import poiReducer from "./slices/pois/poiSlice";
import trackReducer from "./slices/tracks/trackSlice";
import tagReducer from "./slices/tags/tagsSlice";
import imageReducer from "./slices/images/imagesSlice";
import videoReducer from "./slices/video/videoSlice";
import audioReducer from "./slices/audio/audioSlice";
import descriptionsReducer from "./slices/descriptions/descriptionsSlice";
import loginReducer from "./slices/login/loginSlice";

const store = configureStore({
  reducer: {
    colors: colorsReducer,
    config: configReducer,
    leftSidebar: leftSidebarReducer,
    navigation: navigationReducer,
    poi: poiReducer,
    tag: tagReducer,
    image: imageReducer,
    video: videoReducer,
    audio: audioReducer,
    descriptions: descriptionsReducer,
    track: trackReducer,
    login: loginReducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
