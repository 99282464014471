import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../../store';
import Parse from 'parse';
import { OwnerId } from '../models/models';



export interface TrackId {
    title: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface PoiId {
    title: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface UserId {
    username: string;
    createdAt: Date;
    updatedAt: Date;
    sessionToken: string;
    objectId: string;
    __type: string;
    className: string;
}

export interface DescriptionData {
    id: string;
    description: string;
}


export interface Description {


    status: string;
    owner_id: OwnerId;
    owner_type: string;
    user_id: UserId;
    poi_id: PoiId;
    track_id: TrackId;
    description: string;
    createdAt: Date;
    updatedAt: Date;
    objectId: string;

}

export interface ResponseObject {
    results: Description[];
    count: number;
}

export interface ParseResponseObject {
    results: Parse.Object[];
    count: number;
}


export interface DescriptionState {
    isLoading: boolean;
    uploading: boolean;
    descriptions?: ResponseObject
    reload: boolean;
    pagination: {
        size: number,
        page: number,
        searchText: string,
        status: string[],
        userTypes: string[],
        ascending: boolean,
        sortColumn: string
    }

}


const initialState: DescriptionState = {
    isLoading: false,
    uploading: false,
    descriptions: {
        count: 0,
        results: []
    },
    reload: false,
    pagination: {
        size: 10,
        page: 0,
        searchText: "",
        status: ["published"],
        ascending: false,
        sortColumn: 'updatedAt',
        userTypes: ['parco', 'turista', 'abituale', 'residente']


    }

};


const getPaginatedDescriptions = createAsyncThunk(
    'getPaginatedDescriptions',
    async (_, { getState }) => {
        const { size, page, searchText, userTypes, status, ascending, sortColumn } = (getState() as AppState).descriptions.pagination;

        const query = new Parse.Query('Descrizione');
        query.withCount();
        query.include('owner_id');

        query.include('poi_id')
        query.include('track_id')

        if (searchText) {
            query.fullText('description', searchText);
        }

        if (userTypes) {
            query.containedIn('owner_type', userTypes)
        }

        if (status) {

            query.containedIn('status', status);
        }

        if (ascending) {
            query.ascending(sortColumn)
        }
        else {
            query.descending(sortColumn)
        }
        query.skip(page * size);
        query.limit(size)
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());

        let res: ResponseObject = { count: count, results: results } as unknown as ResponseObject;







        return res;

    }
)


const getDescription = createAsyncThunk(
    'getDescription',
    async () => {

        const query = new Parse.Query('Description');
        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
)

const getDescriptionOfObject = createAsyncThunk(
    'getDescriptionOfObject',
    async ({ objectId, type }: { objectId: string, type: 'Poi' | 'Track' }) => {



        const query = new Parse.Query('Descrizione');
        const Classe = Parse.Object.extend(type);
        const obj = Classe.createWithoutData(objectId);

        query.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
        query.equalTo('status', 'published');
        query.include('owner_id')

        query.limit(10000)
        query.descending('updatedAt')


        query.withCount();
        const response = (await query.find()) as unknown as ParseResponseObject;
        const count = response.count;
        const results = response.results.map((p) => p.toJSON());





        return { count: count, results: results } as unknown as ResponseObject;

    }
);

const removeDescription = createAsyncThunk(
    'removeDescription',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Descrizione');
        const v = await query.get(id);
        v.set('status', 'deleted')
        await v.save();


        // const { pagination, descriptions } = (getState() as AppState).descriptions;
        // if (descriptions && pagination) dispatch(getPaginatedDescriptions())

        // dispatch(getDescriptionOfObject({ objectId, type }))

        if (view === 'list') {
            const { pagination, descriptions } = (getState() as AppState).descriptions;
            if (descriptions && pagination) dispatch(getPaginatedDescriptions())

        }
        else {

            dispatch(getDescriptionOfObject({ objectId, type }))


        }




    }
)


const definitelyRemoveDescription = createAsyncThunk(
    'definitelyRemoveDescription',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Descrizione');
        const v = await query.get(id);
        v.set('status', 'defdeleted')
        await v.save();
        // dispatch(getDescriptionOfObject({ objectId, type }))

        if (view === 'list') {
            const { pagination, descriptions } = (getState() as AppState).descriptions;
            if (descriptions && pagination) dispatch(getPaginatedDescriptions())

        }
        else {

            dispatch(getDescriptionOfObject({ objectId, type }))


        }


        // const { pagination, descriptions } = (getState() as AppState).descriptions;
        // if (descriptions && pagination) dispatch(getPaginatedDescriptions())




    }
)


const deleteDescription = createAsyncThunk(
    'deleteDescription',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Descrizione');
        const v = await query.get(id);
        v.set('status', 'deleted')
        await v.save();


        // const { pagination, descriptions } = (getState() as AppState).descriptions;
        // if (descriptions && pagination) dispatch(getPaginatedDescriptions())

        // dispatch(getDescriptionOfObject({ objectId, type }))

        if (view === 'list') {
            const { pagination, descriptions } = (getState() as AppState).descriptions;
            if (descriptions && pagination) dispatch(getPaginatedDescriptions())

        }
        else {

            dispatch(getDescriptionOfObject({ objectId, type }))


        }




    }
)


const definitelyDeleteDescription = createAsyncThunk(
    'definitelyDeleteDescription',


    async ({ id, objectId, type, view }: { id: string, objectId: string, type: "Poi" | "Track", view: 'list' | 'detail' }, { dispatch, getState }) => {

        const query = new Parse.Query('Descrizione');
        const v = await query.get(id);
        v.set('status', 'defdeleted')
        await v.save();
        // dispatch(getDescriptionOfObject({ objectId, type }))





        if (view === 'list') {
            const { pagination, descriptions } = (getState() as AppState).descriptions;
            if (descriptions && pagination) dispatch(getPaginatedDescriptions())

        }
        else {

            dispatch(getDescriptionOfObject({ objectId, type }))


        }




    }
)
const publishDescription = createAsyncThunk(
    'publishDescription',
    async ({ id, objectId, type }: { id: string, objectId: string, type: "Poi" | "Track" }) => {

        const query = new Parse.Query('Descrizione');
        const v = await query.get(id);
        v.set('status', 'published')
        await v.save();




        return {}

    }
)


export const uploadDescription = createAsyncThunk(
    'uploadDescription',
    async ({ description, objectId, type }: { description: string, objectId: string, type: "Poi" | "Track" }) => {


        try {

            const user = Parse.User.current()
            const userId = user && user.id ? user.id : null;
            const DescriptionClass = Parse.Object.extend('Descrizione')
            const newDescription = new DescriptionClass()
            const Classe = Parse.Object.extend(type);
            const obj = Classe.createWithoutData(objectId);

            newDescription.set('description', description)
            newDescription.set('owner_id', user)
            console.log("userId", userId);
            newDescription.set('owner_type', 'parco')
            newDescription.set(type === 'Poi' ? 'poi_id' : 'track_id', obj)
            newDescription.set('status', 'published')

            await newDescription.save()

            const queryDescription = new Parse.Query('Descrizione');

            queryDescription.equalTo(type === 'Poi' ? 'poi_id' : 'track_id', obj);
            queryDescription.equalTo('status', 'published');
            queryDescription.descending('updatedAt')
            queryDescription.include('owner_id')
            const res = await queryDescription.find();
            const results = res.map((p) => p.toJSON());

            await new Promise((resolve) => setTimeout(resolve, 3000));

            return { count: res.length, results: results } as unknown as ResponseObject;
            // }, 3000);




        } catch (error) {
            console.log(error)

        }


    }
);



interface PaginationParams {
    page?: number,
    size?: number,
    searchText?: string,
    status?: string[],
    userTypes?: string[],
    ascending?: boolean,
    sortColumn?: string,
}


export const descriptionsSlice = createSlice({
    name: 'descriptions',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPaginationParams: (state, action: PayloadAction<PaginationParams>) => {
            const { page, size, searchText, sortColumn, ascending, userTypes, status } = action.payload

            if (page !== undefined) state.pagination.page = page
            if (size) state.pagination.size = size
            if (searchText) state.pagination.searchText = searchText
            if (sortColumn) state.pagination.sortColumn = sortColumn
            if (ascending !== undefined) state.pagination.ascending = ascending
            if (userTypes) state.pagination.userTypes = userTypes
            if (status) state.pagination.status = status

            return state
        }

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getPaginatedDescriptions.pending, (state) => {
                state.isLoading = true;
                state.reload = false
            })
            .addCase(getPaginatedDescriptions.rejected, (state, action) => {
                state.isLoading = false;
                state.reload = false

            })
            .addCase(getPaginatedDescriptions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = false

                state.descriptions = action.payload;
            })
            .addCase(getDescription.pending, (state) => {
                state.isLoading = true;
                state.reload = false

            })
            .addCase(getDescription.rejected, (state, action) => {
                state.isLoading = false;
                state.reload = false


            })
            .addCase(getDescription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = false

                state.descriptions = action.payload;
            })
            .addCase(definitelyRemoveDescription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyRemoveDescription.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(definitelyRemoveDescription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true

            })
            .addCase(removeDescription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeDescription.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(removeDescription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true

            })
            .addCase(definitelyDeleteDescription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(definitelyDeleteDescription.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(definitelyDeleteDescription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true

            })
            .addCase(deleteDescription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDescription.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(deleteDescription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true

            })
            .addCase(publishDescription.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(publishDescription.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(publishDescription.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = true

            })
            .addCase(getDescriptionOfObject.pending, (state) => {
                state.isLoading = true;
                state.reload = false

            })
            .addCase(getDescriptionOfObject.rejected, (state) => {
                state.isLoading = false;
                state.reload = false


            })
            .addCase(getDescriptionOfObject.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reload = false

                state.descriptions = action.payload;

            })
            .addCase(uploadDescription.pending, (state) => {
                state.uploading = true;
                state.reload = false

            })
            .addCase(uploadDescription.rejected, (state) => {
                state.uploading = false;
                state.reload = false


            })
            .addCase(uploadDescription.fulfilled, (state, action) => {
                state.uploading = false;
                state.reload = false

                state.descriptions = action.payload;

            });
    },
});




// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: AppState) => state.counter.value)`

export const selectDescription = (state: AppState) => state.descriptions;
export const selectPagination = (state: AppState) => state.descriptions.pagination;

export const descriptionsActions = { ...descriptionsSlice.actions, getPaginatedDescriptions, getDescription, definitelyRemoveDescription, removeDescription, getDescriptionOfObject, uploadDescription, publishDescription }

export default descriptionsSlice.reducer;
